
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import events from "./events";
import { scheduleService } from "../../service/scheduleService";

interface ReactBigCalendarProps {
  view: "day" | "week" | "month";
  setView: React.Dispatch<React.SetStateAction<"day" | "week" | "month">>;
  selectedDate: Date;
  // setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
}

export default function ReactBigCalendar({
  view,
  setView,
  selectedDate,
  // setSelectedDate,
}: ReactBigCalendarProps) {
  const localizer = momentLocalizer(moment);
  const [eventsData, setEventsData] = useState<any>(events);

  const handleViewChange = (newView: "day" | "week" | "month") => {
    setView(newView);
  };

//   useEffect (()=>{
//     getOperatorScheduleList()
//   }, [])

//   const getOperatorScheduleList = async () => {
//     try {
//     const response = await scheduleService.getCalenderScheduleList(
//     );
//     if (response.status && response.statusCode === 200) {
//         try {
//         if (Array.isArray(response.data) && response.data?.length > 0) {
//             let rowData: any = response.data.map(
//             (element: any, index: number) => {
//                 return {
//                 slno: index + 1,
//                 id: element.id,
//                 activity_type_name: element?.activity_type_name,
//                 user_first_name: element?.user_first_name,
//                 user_last_name: element?.user_last_name,
//                 location_name: element?.location_name,
//                 area_name: element?.area_name,
//                 production_schedule_name: element?.production_schedule_name,
//                 start_date_time: element?.start_date_time
//                 ? moment(
//                     element?.start_date_time,
//                     "YYYY-MM-DD HH:mm:ss"
//                 ).format("DD-MM-YYYY - HH:mm:ss")
//                 : "-",
//                 end_date_time: element?.end_date_time
//                 ? moment(
//                     element?.end_date_time,
//                     "YYYY-MM-DD HH:mm:ss"
//                 ).format("DD-MM-YYYY - HH:mm:ss")
//                 : "-",
//                 };
//             }
//             );
//             setEventsData(rowData);
//         }
//         } catch (error) {
//         console.log(error);
//         }
//     } else {
//       setEventsData([]);
//     }
//     } catch (error) {
//     console.error("user response", error);
//     }
// };

  const CustomToolbar = ({ label, onView, onNavigate }: any) => {
    return (
      <div className="rbc-toolbar">
        {/* Label showing current month/year */}
        <div className="rbc-toolbar-label">{label}</div>
        
        {/* View Buttons: Day, Week, Month */}
        <div className="rbc-btn-group">
          <button onClick={() => onView('day')}>Day</button>
          <button onClick={() => onView('week')}>Week</button>
          <button onClick={() => onView('month')}>Month</button>
        </div>
      </div>
    );
  };
  
  return (
    <div className="calender-view-schedule">
      <Calendar
        views={["day", "week", "month"]}
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView={view}
        events={eventsData}
        style={{ height: "80vh" }}
        date={selectedDate}
        onView={(newView) => handleViewChange(newView as "day" | "week" | "month")}
        components={{
          toolbar: CustomToolbar, 
        }}
      />
    </div>
  );
}





