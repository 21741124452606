import React, { useEffect, useState } from "react";
import HeaderSection from "../../../common/headerSection";
import PageHeader from "../../../common/pageHeader";
import CustomTable from "../../../../widgets/table";
import Pagination from "../../../../widgets/pagination";
import { columns } from "./columns";
import { workflow } from "../../../../service/workflow";
import WorkflowInnerPage from "..";
import AlertModal from "../../../../widgets/alertModal";
import CustomToast from "../../../../widgets/toaster";
import WorkflowModal from "./workFlowModal";
import { Modal } from "react-bootstrap";


const WorkflowDesign = () => {

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [useEffectRequired, setUseEffectRequired] = useState(false);
    const [workflowList, setworkflowList] = useState<Array<any>>([]);
    const [tab , setTab] = useState("1")
    const [buttonState, setButtonState] = useState("grey");
    const [workflowData, setWorkFlowData] = useState({
      name: "",
      description: "",
      activity: "",
    });
    const [activity, setActivity] = useState<Array<any>>([]);
    const [areaAlert , setAreaAlert] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState("");
    const [selectedIds, setSelectedIds] = useState("");
    const [editWorkFlow, setEditWorkFlow] = useState<any>({
      id: 0,
      processDetails: [],
      status: "",
      name: "",
      master_activity_id: 0,
    });
    const [flowName , setflowName] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [statusChangeAlert, setStatusChangeAlert] = useState(false);
    const [updatedStatus, setUpdatedStatus] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [submitFlow, setsubmitFlow] = useState(false);
    const [publishId, setPublishId] = useState("");
    const [errors, setErrors] = useState<{ name?: string; activity?: string;  description?: string }>({});

    const handlePageChange = (newPage: any, pageSize?: number) => {
        setPage(newPage);
        if (pageSize) {
          setPageSize(pageSize);
        }
        setUseEffectRequired(true);
    };

    const handleAddClick = () => {
      setTab("2")
      };

    const handleEditClick = (id: string) => {
    const filteredUserDetails = workflowList.filter((item) => item.slno === id);
    const selectedIds = filteredUserDetails.map((item) => item.id).join(',');
    setSelectedIds(selectedIds)
    getWorkFlowById(selectedIds)
    setTab("3")
    }

    const handleFinalSubmitClick = (id: string) => {
      // const filteredUserDetails = workflowList.filter((item) => item.id === id);  
      setPublishId(id);
      setsubmitFlow(true)
    }

    const getWorkFlowById = async (id : string) => {
    console.log("--")
    try {
      const response = await workflow.EditById(id);
      if (response.status && response.statusCode === 200) {
        try {
          const rowData = {
                id: response.data.id,
                processDetails : response.data.process_details,
                status : response.data.status,
                name: response.data.name,
                master_activity_id: response.data.master_activity_id
          }

          setEditWorkFlow(rowData);
          // handleEditRow(rowData?.processDetails[0]?.id)
          // ProcessList(response.data.master_activity_id);
          setflowName(response.data.name);

        } catch (error) {
          console.log(error);
        }
      }else{
        setEditWorkFlow({
          id: 0,
          processDetails: [],
          status: "",
          name: "",
          master_activity_id: 0,
        })
      }
    } catch (error) {
      console.error("user response", error);
    }
  };


    const listWorkFlowType = async () => {
        try {
          const response = await workflow.WorkflowList(page, pageSize);
          if (response.status && response.statusCode === 200) {
            try {
              if (Array.isArray(response.data) && response.data?.length > 0) {
                setTotalPages(response?.totalPages);
                setTotalElements(response?.totalElements);
                let rowData: any = response.data.map(
                  (element: any, index: number) => {
                    return {
                      slno: index + 1,
                      id: element.id,
                      name : element.name,
                      activity_name : element.activity_name,
                      status : element.status,
                      // publish_status: element.publish_status
                      publish_status: element.publish_status
                      ? element.publish_status.charAt(0).toUpperCase() + element.publish_status.slice(1)
                      : element.publish_status
                    };
                  }
                );
                setworkflowList(rowData);
              }
            } catch (error) {
              console.log(error);
            }
          }
        } catch (error) {
          console.error("user response", error);
        }
      };

      const ActivityList = async () => {
        try {
          const response = await workflow.ActivityList("");
          if (response.status && response.statusCode === 200) {
            try {
              if (Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any) => {
                  return {
                    id: element.id,
                    name: element.name,
                  };
                });
                setActivity(rowData);
              }
            } catch (error) {
              console.log(error);
            }
          }
        } catch (error) {
          console.error("user response", error);
        }
      };

      const handleStatusChange = (id: string, status: string) => {
        setSelectedId(id);
        if (status === "active") {
          setUpdatedStatus("inactive");
        } else {
          setUpdatedStatus("active");
        }
        setStatusChangeAlert(true);
      };

       //change area status api call
      const handleStatusChangeSubmit = async () => {

        const dataToSend = {
          "workflow_id": selectedId,
          "status": updatedStatus,
          "interface_name": "work flow",
        }

        try {
          const response = await workflow.changeWorkflowStatus(dataToSend);

          if (response.status && response.statusCode === 200) {
            setToastType("success");
            setToastMessage(response.message);
          } else {
            setToastType("error");
            setToastMessage(response.message);
          }
          setShowToast(true);
          setUseEffectRequired(true);
          setUpdatedStatus("");
          setSelectedId("");
          setStatusChangeAlert(false);
        } catch (error) {
          console.error(error);
        }
      };

      const handleWorkflowSubmit = async () => {
        const dataToSend = {
          "workflow_id": publishId,
          "publish_status": "published",
          "interface_name": "work flow",
        }

        try {
          const response = await workflow.PublishWorkflow(dataToSend);

          if (response.status && response.statusCode === 200) {
            setToastType("success");
            setToastMessage(response.message);
          } else {
            setToastType("error");
            setToastMessage(response.message);
          }
          setShowToast(true);
          setUseEffectRequired(true);
          setPublishId("");
          setsubmitFlow(false);
        } catch (error) {
          console.error(error);
        }
      }

      const handleAddUserChange = (e: any) => {
        const { name, value } = e.target;
        setWorkFlowData((prevFormData: any) => ({
          ...prevFormData,
          [name]: value,
        }));
      };

      const handleSubmit = () => {
        const newErrors: { name?: string; activity?: string, description?: string } = {};
        const specialCharPattern = /^[a-zA-Z0-9\s-]*$/;

        // Validate 'name' field
        if (!workflowData.name?.trim()) {
          newErrors.name = "Workflow Name is required";
        } else if (workflowData.name.length > 100) {
          newErrors.name = "Workflow Name cannot exceed 100 characters";
        }else if (!specialCharPattern.test(workflowData.name)) {
          newErrors.name = "Special characters are not allowed in the name";
        }
        // Validate 'activity' field
        if (!workflowData.activity?.trim()) {
          newErrors.activity = "Please Select an Activity";
        }

        if (!workflowData.description?.trim()) {
          newErrors.description = "Please Enter a Description";
        } else if (!specialCharPattern.test(workflowData.description)) {
          newErrors.description = "Description should not contain special characters";
        }


        // Set errors if any
        setErrors(newErrors);

        // Check if there are no errors before proceeding
        if (Object.keys(newErrors).length === 0) {
          setAreaAlert(true)
        }
      };

      useEffect(() => {
        if (workflowData.name && workflowData.activity) {
          setButtonState("fullGreen");
        } else if (workflowData.name) {
          setButtonState("grey");
        } else {
          setButtonState("grey");
        }
      }, [workflowData]);

      const handleCancel = () => {
        setTab("1");
        setWorkFlowData({
          name: "",
          description: "",
          activity: "",
        })
        setErrors({})
      };

      const handleAddFlowName = async () => { 
        const dataToSend = {
          id: "0",
          name : workflowData.name,
          description : workflowData.description,
          master_activity_id : workflowData.activity,
          // interface_name : "WorkFlow"
        }
        try {
          const response = await workflow.registerWorkflow(dataToSend);
          if (response.status && response.statusCode === 200) {
            setToastType("success")
            setToastMessage(response.message)
            setTab("1");
            setWorkFlowData({
              name: "",
              description: "",
              activity: "",
            })
          } else {
            setToastType("error")
            setToastMessage(response.message)
          }
          setAreaAlert(false)
          setShowToast(true)
          
          listWorkFlowType()
          setUseEffectRequired(true)
        } catch (error) {
          console.error(error);
        }
      }

      useEffect(() => {
        listWorkFlowType()
        ActivityList();
        setUseEffectRequired(false)
        },[useEffectRequired])

      const handleViewClick = (id :string) => {
        const filteredUserDetails = workflowList.filter((item) => item.slno === id);
        const selectedIds = filteredUserDetails.map((item) => item.id).join(',');
        setSelectedIds(selectedIds)
        setIsModalOpen(true)
      }

      const handleCloseModal = () => {
        setIsModalOpen(false);
      };

  return (
    <div>
      <HeaderSection />
      <PageHeader pageHeader="WorkFlow" />
      <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        /> 
      {tab == "1" &&(
      <div className="me-3">
        <div className=" mx-3 pe-2" style={{ height: "45vh" }}>
          <CustomTable
            data={workflowList}
            columns={columns}
            isEditable={true}
            totalElements={totalElements}
            isActionVisible={false}
            isViewVisible={true}
            isSeachable={false}
            isToggleStatus={true}
            tableHead="Workflow Table"
            showBatchFilter={false}
            showSerielNoFilter={false}
            addButton={true}
            addButtonText="Add WorkFlow"
            onAddClick={handleAddClick}
            onEditClick={handleEditClick}
            showProcessButton={true}
            onViewClick={handleViewClick}
            showassignUserButton={true}
            onAssignUserClick={handleFinalSubmitClick}
            assignUserBtnText="Publish Workflow"
            onUseEffectRequired={() => setUseEffectRequired(true)}
            onStatusChange={handleStatusChange}
          />
        </div>
        <div>
          <Pagination
            page={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
      )}


        {tab === "2" && (
        <div className="workflow-design">
          <div className="workflow-form">
            <div className="form-group-row selectWrap">
              <div className="form-group1 my-1" style={{ flex: "1" }}>
                <label htmlFor="name" className="name">
                  Workflow Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={workflowData.name}
                  onChange={handleAddUserChange}
                  required
                />
                 {errors.name && <p className="errorText">{errors.name}</p>}
              </div>
              <div className="form-group1 my-1" style={{ flex: "1" }}>
                <label htmlFor="activity" className="name">
                  Activity <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  id="activity"
                  value={workflowData.activity}
                  onChange={handleAddUserChange}
                  name="activity"
                  required
                >
                  <option value="">Select</option>
                  {activity.map((obj, index) => (
                    <option key={index} value={obj?.id}>
                      {obj?.name}
                    </option>
                  ))}
                </select>
                {errors.activity && <p className="errorText">{errors.activity}</p>}
              </div>
            </div>
            <div className="form-group1 selectWrap" style={{display: "flow"}}>
              <label htmlFor="description" className="name">
                Description <span style={{ color: "red" }}>*</span>
              </label>
              <textarea
                id="description"
                value={workflowData.description}
                onChange={handleAddUserChange}
                name="description"
              />
               {errors.description && <p className="errorText">{errors.description}</p>}
            </div>
            
            {/* <div className="button-container1">
              <button
                onClick={handleCancel}
                type="button"
                className="cancel-button"
              >
                cancel
              </button>
              <button
                onClick={handleSubmit}
                type="submit"
                className={`save-button ${buttonState}`}
              >
                Save
              </button>
            </div> */}
            <div className="button-container1">
            <button
               onClick={handleCancel}
              className="modalCancelBtn"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className="squareOrangeBtn3"
            >
              Save
            </button>
          </div>

          </div>
        </div>
      )}

      {tab == "3" &&(
        <WorkflowInnerPage
        setTab={setTab}
        activityId={workflowData.activity}
        selectedIds={selectedIds}
        editWorkFlow={editWorkFlow}
        setEditWorkFlow={setEditWorkFlow}
        flowName={flowName}
        />
      )}



      <AlertModal 
        show={areaAlert}
        onHide={() => setAreaAlert(false)}
        title='Alert'
        message={`Add Workflow`}
        onConfirm={handleAddFlowName}
      />

      {/* Alert modal for change work flow status */}
      <AlertModal
        show={statusChangeAlert}
        onHide={() => setStatusChangeAlert(false)}
        title="Alert"
        message={`Change Work Flow Status?`}
        onConfirm={handleStatusChangeSubmit}
      />

      <AlertModal
        show={submitFlow}
        onHide={() => setsubmitFlow(false)}
        title="Alert"
        message={`Submit Workflow`}
        onConfirm={handleWorkflowSubmit}
      />

      {/* {editWorkFlow && ( */}
        <WorkflowModal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          workflowId={selectedIds}
        />
      {/* )} */}





    </div>
  );
};

export default WorkflowDesign; 
