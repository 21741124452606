import React, { useState, useEffect, useRef } from 'react'
import {BsQrCodeScan} from 'react-icons/bs'
import CustomTable from '../../../widgets/table/index'
import HeaderSection from '../../common/headerSection';
import PageHeader from '../../common/pageHeader';
import CustomToast from '../../../widgets/toaster';
import { userService } from '../../../service/plateServices';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import AddException from '../../common/addException';
import Pagination from '../../../widgets/pagination';
import { areaService } from '../../../service/areaServices';
import moment from 'moment';
import CountdownTimer from '../../common/countDownTimer';
import { SerielNumberScanner } from '../common/serielNumberScanner';
import { getColumns, columnsHistory } from './partials/columns';
import { useTranslation } from 'react-i18next';
import { IoSearchSharp } from 'react-icons/io5';

interface TableColumn {
  Header: string;
  accessor: string;
}

interface LocationData {
  value: string;
  label: string;
  type: string;
}

const IncubationArea = () => {
    const { t } = useTranslation();
    const scanAreaRef = useRef<HTMLInputElement>(null);


    const [tab, setTab] = useState("1")
    const [show, setShow] = useState(false)
    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState("")
    const [toastType, setToastType] = useState("")
    const [scanCode, setScanCode] = useState("");
    const [useEffectRequired, setUseEffectRequired] = useState(false);
    const [userId, setUserId] = useState<number>(0);
    const areaId : string | undefined = useSelector((state: RootState) => state.area.areaId);
    const areaName = useSelector((state: RootState) => state.area.areaName);
    const [plateDetails, setPlateDetails] = useState<Array<any>>([]);

    // pagination
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(5)
    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)

    // pagination History
    const [incubationHistory, setIncubationHistory] = useState<Array<any>>([])
    const [pageHistory, setPageHistory] = useState(1)
    const [pageSizeHistory, setPageSizeHistory] = useState(5)
    const [totalPagesHistory, setTotalPagesHistory] = useState(0)
    const [totalElementsHistory, setTotalElementsHistory] = useState(0)

    const [plateStatus, setPlateStatus] = useState("9,5,24,25,27,28,31,11,13")
    const [nextProcess, setnextProcess] = useState("incubation")
    const [locationId, setLocationId] = useState<string | undefined>("")
    const [location, setLocation] = useState("")
    const [locationData, setlocationData] = useState({
      value: "",
      label: "",
      type: "",
    })
    const [plateBatches, setPlateBatches] = useState([])
    const [plateSerielNum, setPlateSerielNums] = useState([])
    const [areaTypeNum, setAreaTypeNum] = useState([])
    const [keyWord, setkeyWord] = useState("")
    const [incubationDuration, setIncubationDuration] = useState(0)
    const [incubatorName, setIncubatorName] = useState("")
    const [capacityDetails, setCapacityDetails] = useState("")
    const [duration , setDuration] = useState("")
    const [serielScanShow, setSerielScanShow] = useState(false);
    const data: Record<string, string | number> = {
      "Area Name": incubatorName,
      "Capacity": capacityDetails,
      "Duration" : duration
    };
    const [isSerielScanned, setIsSerielScanned] = useState(false)
    const [sort, setSort] = useState("id");
    const [order, setOrder] = useState("desc");
    const [selectedSerielNo, setSelectedSerielNo] = useState("");
    const [debouncedScanCode, setDebouncedScanCode] = useState('');
    const [activityType, setActivityType] = useState<Array<any>>([]);
    const [activityStatus, setActivityStatus] = useState("")
    const [columns, setColumns] = useState<TableColumn[]>([]); 

    
    //get user details 
    useEffect(() => {
      var storedData = localStorage.getItem('userDetails');
  
      if(storedData) {
        var storedObject = JSON.parse(storedData);
        setUserId(storedObject[0].id)
      }


      ListPlateDetails()
      getActivityTypeList()
      ListIncubationHistory()
      getAreaByScan(areaId, "")
      listPlateBatch()
      listSerialNumber()
      listAreaType()
      setUseEffectRequired(false)
    }, [useEffectRequired])


    useEffect(() => {
      if (scanAreaRef.current) {
        scanAreaRef.current.focus();
      }
    }, [])

    //get area details from scan area qr values 
    const getAreaByScan = async (id : any , location_type : any ) => {
      try {
        const response = await areaService.getAreaByScan(id, location_type);
        if(response.status && response.statusCode === 200){
          try {   
              setIncubationDuration(response.data.incubator_policy_duration);
              setIncubatorName(response.data.incubator_name)
              setCapacityDetails(`${response.data.busy_node_count} / ${response.data.incubator_capacity}`)
              setDuration(response.data.incubator_policy_duration)
            } catch (error) {
              console.log(error);
            }
        } 
      } catch (error) {
        console.error('user response', error);
      }
    };

    //list plates details for the main table 
    const ListPlateDetails = async () => {
      setIsSerielScanned(false)
      
        try {
          const response = await userService.ListPlateByStatus(page, pageSize,sort, order, plateStatus, locationId, keyWord, location, activityStatus, nextProcess);
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  setTotalPages(response?.totalPages)
                  setTotalElements(response?.totalElements)

                  let rowData: any = response.data.map((element: any, index: number) => {

                    const statusNumber = parseInt(element.plate_status, 10); 
                    const pendingStatusNumbers = [9, 5, 25, 24, 27, 28, 31, 11, 13];

                    if(pendingStatusNumbers.includes(statusNumber)){
                       var status = "Pending"
                    }else if (statusNumber == 12){
                       var status = "In Progress"
                    }else if (statusNumber == 13){
                       var status = "Completed"
                    }else{
                       var status = "Unknown status"
                    }
                    interface Exposure {
                     id: string;
                     start_date_time: string;
                     end_date_time: string;
                     updated_by_first_name: string;
                     updated_by_last_name: string;
                     exposure_area_name: string;
                   }
                   // Calculate total exposed time
                   const exposureDetails : Exposure[] = element.exposure_details || [];
                   let totalExposedTime = 0;
   
                   exposureDetails.forEach((exposure) => {
                     const startDate = new Date(exposure.start_date_time);
                     const endDate = new Date(exposure.end_date_time);
                     const timeDifferenceMs = endDate.getTime() - startDate.getTime();
                     totalExposedTime += timeDifferenceMs;
   
   
                   });
                   const totalExposedTimeHours = Math.floor(totalExposedTime / 3600000);
                   const totalExposedTimeMinutes = Math.floor(
                     (totalExposedTime % 3600000) / 60000
                   );
                   const totalExposedTimeSeconds = Math.floor((totalExposedTime % 60000) / 1000);
   
                   const fullNames = exposureDetails.map((exposure : Exposure) => {
                     return `${exposure.updated_by_first_name} ${exposure.updated_by_last_name}`;
                   });
                   const exposed_user = fullNames.join(", ");
                   const exposureAreaName = element.exposure_details[0]?.exposure_area_name || '-';
                   
                   const formattedStartDateTime = exposureDetails.map((exposure) => moment(exposure.start_date_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD hh:mm:ss A'));
                   const formattedEndDateTime = exposureDetails.map((exposure) => moment(exposure.end_date_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD hh:mm:ss A'));
                   //caluculate the total incubated time 
                   interface Incubation {
                     id: string;
                     start_date_time: string;
                     end_date_time: string;
                     created_by_first_name: string;
                     updated_by_first_name: string;
                     updated_by_last_name: string;
                   }
                   const incubationDetails : Incubation[] = element.incubation_details || [];
                   let totalIncubatedTime = 0;
                   incubationDetails?.forEach((incubation)=>{
                     const startDate = new Date(incubation.start_date_time);
                     const endDate = incubation.end_date_time ? new Date(incubation.end_date_time) : null;
                     if (endDate) {
                       const timeDifferenceMs = endDate.getTime() - startDate.getTime();
                       totalIncubatedTime += timeDifferenceMs;
                     }
                   });
                   const totalIncubatedTimeHours = Math.floor(totalIncubatedTime / 3600000);
                   const totalIncubatedTimeMinutes = Math.floor(
                     (totalIncubatedTime % 3600000) / 60000
                   );
                   const totalIncubatedTimeSeconds = Math.floor((totalIncubatedTime % 60000) / 1000);
                   const fullNamesIncubation = incubationDetails.map((incubation : Incubation) => {
                    if(incubation.updated_by_first_name != null || incubation.updated_by_last_name != null){
                      return `${incubation.updated_by_first_name} ${incubation.updated_by_last_name}`;
                    }  
                  });
                   const incubated_user = fullNamesIncubation.join(", ");
                   const incubationAreaName = response.data || '-';
    

                  
                  // const startIncubationTime = moment(element?.incubation_details[0]?.start_date_time, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss');
                  // const [startHours, startMinutes] = startIncubationTime.split(':').map(Number);
                  // // Calculate the endIncubationTime
                  // let endIncubationTime = ""

                  // if(incubationDuration) {
                  //   const duration = Number(incubationDuration);
                  //   let endHour = startHours;
                  //   let endMinute = startMinutes + duration;

                  //   if (endMinute >= 60) {
                  //     endHour += Math.floor(endMinute / 60);
                  //     endMinute %= 60;
                  //   }

                  //   endIncubationTime = `${String(endHour).padStart(2, "0")}:${String(endMinute).padStart(2, "0")}`;
                  // }
                  // endIncubationTime = "12:31"

                  let startIncubationTime = ""
                  let endIncubationTime = "";

                  if(element.plate_status == "12"){
                    startIncubationTime = moment(element?.incubation_details[element?.incubation_details?.length - 1]?.start_date_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
                  } 

                  endIncubationTime = moment(element?.incubation_details[element?.incubation_details?.length - 1]?.expected_end_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');

                  // if (incubationDuration) {
                  //   const duration = Number(incubationDuration);
                  //   const startTimeMoment = moment(startIncubationTime, 'YYYY-MM-DD HH:mm:ss');
                  //   const endIncubationMoment = startTimeMoment.clone().add(duration, 'minute');
                  //   endIncubationTime = endIncubationMoment.format('YYYY-MM-DD HH:mm:ss');
                  // }
                    return { 
                        slno: index + 1, 
                        id: element.id,
                        batch_number: element.batch_number,
                        serial_number: element.serial_number,
                        // plate_type: element.plate_type,
                        exposure_start_time : formattedStartDateTime,
                        exposureEndTime: formattedEndDateTime,
                        exposed_time: `${totalExposedTimeHours} : ${totalExposedTimeMinutes} : ${totalExposedTimeSeconds}`,
                        exposed_by : exposed_user,
                        exposure_area_name : exposureAreaName,
                        incubation_duration: `${totalIncubatedTimeHours} : ${totalIncubatedTimeMinutes} : ${totalIncubatedTimeSeconds}`,
                        incubated_by : incubated_user,
                        incubation_area_name : incubationAreaName ,
                        plate_type : element.assigned_activity_name,
                        expiry_date: element.expiry_date,
                        status : status,
                        remainingTime : <CountdownTimer endTime={endIncubationTime} status={status} />
                    };
                  });
                  // setPlateDetails(rowData);
                  // const updatedColumns = getColumns(rowData[0]?.status);
                  // setColumns(updatedColumns);
                  if (rowData.length === 0) {
                    setPlateDetails([]);
                    setColumns(getColumns("Pending")); 
                  } else {
                    setPlateDetails(rowData);
                    const updatedColumns = getColumns(rowData[0]?.status);
                    setColumns(updatedColumns);
                  }
                }
              } catch (error) {
                console.log(error); 
              }
          } else {
            setPlateDetails([])
            setTotalElements(0)
            setColumns(getColumns("Pending")); 
          }
        } catch (error) {
          console.error('Plate response', error);
        }
    };

    //plate details for listing history table 
    const ListIncubationHistory = async () => {
        try {
          const response = await userService.ListIncubationHistory(page, pageSizeHistory,sort,order,keyWord ,areaId);
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  setTotalPagesHistory(response?.totalPages)
                  setTotalElementsHistory(response?.totalElements)
                  let rowData: any = response.data.map((element: any, index: number) => {              
                    let totalIncubatedTime = 0;
                   
                      const startDate = new Date(element.start_date_time);
                      const endDate = element.end_date_time ? new Date(element.end_date_time) : null;
                      if (endDate) {
                        const timeDifferenceMs = endDate.getTime() - startDate.getTime();
                        totalIncubatedTime += timeDifferenceMs;
                      }
                   
                    const totalIncubatedTimeHours = Math.floor(totalIncubatedTime / 3600000);
                    const totalIncubatedTimeMinutes = Math.floor(
                      (totalIncubatedTime % 3600000) / 60000
                    );
                    const totalIncubatedTimeSeconds = Math.floor((totalIncubatedTime % 60000) / 1000);
                 
                    //  new code
                  const startIncubationTime = moment(element?.start_date_time, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY - HH:mm:ss');
                  const [startHours, startMinutes] = startIncubationTime.split(':').map(Number);
                  // Calculate the endIncubationTime
                  let endIncubationTime = ""

                  if(incubationDuration) {
                    const duration = Number(incubationDuration);
                    let endHour = startHours;
                    let endMinute = startMinutes + duration;

                    if (endMinute >= 60) {
                      endHour += Math.floor(endMinute / 60);
                      endMinute %= 60;
                    }

                    endIncubationTime = `${String(endHour).padStart(2, "0")}:${String(endMinute).padStart(2, "0")}`;
                  }
                  // endIncubationTime = "19:50"
                    return { 
                        slno: index + 1, 
                        id: element.id,
                        batch_number: element.batch_number,
                        serial_number: element.serial_number,
                        plate_type: element.master_activity_name,                     
                        incubation_duration: `${totalIncubatedTimeHours} : ${totalIncubatedTimeMinutes} : ${totalIncubatedTimeSeconds}`,
                    };
                  });
                  setIncubationHistory(rowData);
                }
              } catch (error) {
                console.log(error); 
              }
          } else {
            setIncubationHistory([])
          }
        } catch (error) {
          console.error('Plate response', error);
        }
    };

    //on start incubation 
    const handleStartClick = async (selectedRows: string[]) => {
      const filteredPlateDetails = plateDetails.filter((item) => selectedRows.includes(item.slno));
      const selectedIds = filteredPlateDetails.map((item) => item.id).join(',');

      const dataToSend = {
        "user_id" : userId,
        "plate_ids" : selectedIds,
        "location_id" : areaId,
        "interface_name" : "Add Incubation"
      }

      try {
        const response = await userService.startPlateIncubation(dataToSend);
        if(response.status && response.statusCode === 200){
          setPlateStatus("12")
          setnextProcess("incubation")
          setUseEffectRequired(true)
          setToastType("success")
          setToastMessage(response.message)
        } else {
          setToastType("error")
          setToastMessage(response.message)
        }
        setShowToast(true)
      } catch (error) {
        console.error('Plate response', error);
      }
    }

    //on end incubation 
    const handleEndClick = async (selectedRows: string[]) => {
      const filteredPlateDetails = plateDetails.filter((item) => selectedRows.includes(item.slno));
      const selectedIds = filteredPlateDetails.map((item) => item.id).join(',');

      const dataToSend = {
        "user_id" : userId,
        "plate_ids" : selectedIds,
        "location_id" : areaId,
        "interface_name" : "End Incubation"
      }

      try {
        const response = await userService.endPlateIncubation(dataToSend);
        if(response.status && response.statusCode === 200){
          setUseEffectRequired(true)
          setToastType("success")
          setToastMessage(response.message)
        } else {
          setToastType("error")
          setToastMessage(response.message)
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    }

    //handle user clicks on remove button in the table 
    const handleRemoveClick = (rowId: string) => {
        const updatedData = plateDetails.filter((row) => row.slno !== rowId);
        const updatedDataWithSlno = updatedData.map((row, index) => ({
          ...row,
          slno: index + 1,
        }));

        setPlateDetails(updatedDataWithSlno); 
    }

    //handle the filter section main table 
    const handleFilter = (key: string, value: any) => {
      if(key === "Area Name") {
          if(value === "9") {
            const modifiedValue = `${value},5,24,25,27,28,31,11,13`;
            setPlateStatus(modifiedValue);
            setnextProcess("incubation")
            setLocationId("")
          } else if (value === "12") {
            setPlateStatus(value)
            setLocationId(areaId)
            setnextProcess("incubation")
          } else if (value === "Status") {
            setPlateStatus("9,5,24,25,27,28,31,11,13")
            setLocationId("")
          } else {
            setLocationId("")
            setnextProcess("")
            setPlateStatus(value)
          }
        } else if (key === "Area Type") {
        setLocation(value)
        const filteredData: LocationData[] = areaTypeNum.filter((item: any) => {
          return item.value === value;
        });
        if(filteredData.length > 0 && filteredData[0]?.type === "6"){
            setPlateStatus("9")
            setLocation(filteredData[0].value)

        }else if (filteredData.length > 0 && filteredData[0]?.type === "10"){
            setPlateStatus("6")
            setLocation(filteredData[0].value)

        }
        setlocationData(filteredData[0])
      } else if (key === "search") {
        setkeyWord(value)
      }else if (key === "Activity Type") {
        if (value === "Select") {
          setActivityStatus("")
        } else {
          setActivityStatus(value)
        }
      }
      setUseEffectRequired(true)
    }

    //handle the filter section in the history table 
    const handleFilterHistory = (key: string, value: any) => {
      if(key === "Area Name") {
        setPlateStatus(value)
      } else if (key === "Area Type") {
        setLocation(value)
        const filteredData: LocationData[] = areaTypeNum.filter((item: any) => {
          return item.value === value;
        });
        if(filteredData.length > 0 && filteredData[0].type === "6"){
            setPlateStatus("9")
            setLocation(filteredData[0].value)
        }else if (filteredData.length > 0 && filteredData[0].type === "10"){
            setPlateStatus("6")
            setLocation(filteredData[0].value)
        }
        setlocationData(filteredData[0])
      } else if (key === "search") {
        setkeyWord(value)
      }
      setUseEffectRequired(true)
    }

    //list the plate batch dropdown 
    const listPlateBatch = async () => {
      try {
        const response = await userService.listPlateBatch();
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {
    
                  return { 
                      value: element.batch_number,
                      label: element.batch_number ,
                  };
                });
                setPlateBatches(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    }

    //list serial number drop down 
    const listSerialNumber = async () => {
      try {
        const response = await userService.listSerialNumber();
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {
    
                  return { 
                      value: element.serial_number,
                      label: element.serial_number ,
                  };
                });
                setPlateSerielNums(rowData);
              }
            } catch (error) {
              console.log(error); 
            }
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    }

    //list area types dropdown 
    const listAreaType = async () => {
      try {
        const response = await areaService.listAreaType();
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {
    
                  return { 
                      value: element.id,
                      label: element.name,
                      type: element.type,
                  };
                });
                setAreaTypeNum(rowData);

              }
            } catch (error) {
              console.log(error); 
            }
        }
      } catch (error) {
        console.error('Plate response', error);
      }
    }

    //handle page change in pagination 
    const handlePageChange = (newPage : any, pageSize?: number) => {
      setPage(newPage);
      if(pageSize) {
        setPageSize(pageSize)
      }
      setUseEffectRequired(true)
    };

    //handle page change in history table pagination 
    const handlePageChangeHistory = (newPage : any, pageSizeHistory?: number) => {
      setPageHistory(newPage);
      if(pageSizeHistory) {
        setPageSizeHistory(pageSizeHistory)
      }
      setUseEffectRequired(true)
    };

    //handle a single serial number is entered in the text area 
    const handleScanCodeChange = async (e: any) => {
      if (!isSerielScanned) {
        setPlateDetails([])
      }
      setScanCode(e)
      if (e.trim() === "") {
        // setPlateDetails([])
      } else {
          try {
            const response = await userService.listAutoClavePlateBySerielNo(e, "incubation");
            if(response.status && response.statusCode === 200){
              try {
                  const dataArray = Array.isArray(response.data) ? response.data : [response.data];

                  let rowData: any = dataArray.map((element: any, index: number) => {

                    const plateType = parseInt(element.plate_type, 10); 
                    if (plateType == 1){
                      var plate_type = "Q A Plate"
                    }else if (plateType == 2){
                      var plate_type = "Exposure Plate"
                    }else if (plateType == 3){
                      var plate_type = "Personnel Monitoring Plate"
                    }else if (plateType == 4){
                      var plate_type = "Sampling Plate"
                    }else if (plateType == 5){
                      var plate_type = "Material Test Plate"
                    }else if (plateType == 6){
                      var plate_type = "Surface Monitoring Plate"
                    }else if (plateType == 7){
                      var plate_type = "Surface Monitoring - SWAB Collection"
                    } else{
                      var plate_type = "-"
                    };

                    const statusNumber = parseInt(element.plate_status, 10); 
                    const pendingStatusNumbers = [9, 5, 25, 24, 27, 28, 31,11,13];
                    if(pendingStatusNumbers.includes(statusNumber)){
                      var status = "Pending"
                    }else if (statusNumber == 12){
                      var status = "In Progress"
                    }else if (statusNumber == 13){
                      var status = "Completed"
                    }else{
                      var status = "Unknown status"
                    }
                    interface Exposure {
                    id: string;
                    start_date_time: string;
                    end_date_time: string;
                    updated_by_first_name: string;
                    updated_by_last_name: string;
                    exposure_area_name: string;
                  }
                  // Calculate total exposed time
                  const exposureDetails : Exposure[] = element.exposure_details || [];
                  let totalExposedTime = 0;

                  exposureDetails.forEach((exposure) => {
                    const startDate = new Date(exposure.start_date_time);
                    const endDate = new Date(exposure.end_date_time);
                    const timeDifferenceMs = endDate.getTime() - startDate.getTime();
                    totalExposedTime += timeDifferenceMs;


                  });
                  const totalExposedTimeHours = Math.floor(totalExposedTime / 3600000);
                  const totalExposedTimeMinutes = Math.floor(
                    (totalExposedTime % 3600000) / 60000
                  );
                  const totalExposedTimeSeconds = Math.floor((totalExposedTime % 60000) / 1000);

                  const fullNames = exposureDetails.map((exposure : Exposure) => {
                    return `${exposure.updated_by_first_name} ${exposure.updated_by_last_name}`;
                  });
                  const exposed_user = fullNames.join(", ");
                  const exposureAreaName = element.exposure_details[0]?.exposure_area_name || '-';
                  
                  const formattedStartDateTime = exposureDetails.map((exposure) => moment(exposure.start_date_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD hh:mm:ss A'));
                  const formattedEndDateTime = exposureDetails.map((exposure) => moment(exposure.end_date_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD hh:mm:ss A'));
                  //caluculate the total incubated time 
                  interface Incubation {
                    id: string;
                    start_date_time: string;
                    end_date_time: string;
                    created_by_first_name: string;
                    updated_by_first_name: string;
                    updated_by_last_name: string;
                  }
                  const incubationDetails : Incubation[] = element.incubation_details || [];
                  let totalIncubatedTime = 0;
                  incubationDetails?.forEach((incubation)=>{
                    const startDate = new Date(incubation.start_date_time);
                    const endDate = incubation.end_date_time ? new Date(incubation.end_date_time) : null;
                    if (endDate) {
                      const timeDifferenceMs = endDate.getTime() - startDate.getTime();
                      totalIncubatedTime += timeDifferenceMs;
                    }
                  });
                  const totalIncubatedTimeHours = Math.floor(totalIncubatedTime / 3600000);
                  const totalIncubatedTimeMinutes = Math.floor(
                    (totalIncubatedTime % 3600000) / 60000
                  );
                  const totalIncubatedTimeSeconds = Math.floor((totalIncubatedTime % 60000) / 1000);

                  const fullNamesIncubation = incubationDetails.map((incubation : Incubation) => {
                    if(incubation.updated_by_first_name != null || incubation.updated_by_last_name != null){
                      return `${incubation.updated_by_first_name} ${incubation.updated_by_last_name}`;
                    }  
                  });
                  const incubated_user = fullNamesIncubation.join(", ");
                  const incubationAreaName = element.incubation_details[0]?.incubation_area_name || '-';

                   //  new code
                   let startIncubationTime = "";
                   // Calculate the endIncubationTime
                   let endIncubationTime = ""

                   if(element.plate_status == "12"){
                    startIncubationTime = moment(element?.incubation_details[element?.incubation_details?.length - 1]?.start_date_time, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss');
                  } 
                  const [startHours, startMinutes] = startIncubationTime.split(':').map(Number);

                   if(incubationDuration) {
                     const duration = Number(incubationDuration);
                     let endHour = startHours;
                     let endMinute = startMinutes + duration;
 
                     if (endMinute >= 60) {
                       endHour += Math.floor(endMinute / 60);
                       endMinute %= 60;
                     }
 
                     endIncubationTime = `${String(endHour).padStart(2, "0")}:${String(endMinute).padStart(2, "0")}`;
                   }
                   const slno = plateDetails.length > 0 ? (isSerielScanned ? plateDetails.length + 1 : 1) : index + 1;

                    return { 
                        slno: slno, 
                        id: element.id,
                        batch_number: element.batch_number,
                        serial_number: element.serial_number,
                        plate_type: plate_type,
                        exposure_start_time : formattedStartDateTime,
                        exposureEndTime: formattedEndDateTime,
                        exposed_time: `${totalExposedTimeHours} : ${totalExposedTimeMinutes} : ${totalExposedTimeSeconds}`,
                        exposed_by : exposed_user,
                        exposure_area_name : exposureAreaName,
                        incubation_duration: `${totalIncubatedTimeHours} : ${totalIncubatedTimeMinutes} : ${totalIncubatedTimeSeconds}`,
                        incubated_by : incubated_user,
                        incubation_area_name : incubationAreaName ,
                        expiry_date: element.expiry_date,
                        status : status,
                        remainingTime : <CountdownTimer endTime={endIncubationTime} status={status}/>

                    };
                  });
                    // setPlateDetails(rowData);
                    // const updatedDataWithSlno = rowData.map((element: any, index: number) => ({
                    //   ...element, // Keep the existing properties
                    //   slno: plateDetails.length > 0 ? (isSerielScanned ? plateDetails.length + 1 : 1) : index + 1,
                    // }));

                    const uniqueUpdatedDataWithSlno = rowData.filter((newElement : any) => {
                      return !plateDetails.some((existingElement) => existingElement.serial_number === newElement.serial_number);
                    });

                    setPlateDetails((prevUserRoleList) => [...prevUserRoleList, ...uniqueUpdatedDataWithSlno]);
                    setIsSerielScanned(true)
              } catch (error) {
                console.log(error); 
              }
            }else {
              // setPlateDetails([])
              setToastMessage(response.message);
              setToastType("error")
              setShowToast(true)
            }
          } catch (error) {
            console.error('Plate response', error);
          }
      }
    }

    const handleScanCodeClear = () => {
      setScanCode("")
      setSelectedSerielNo("")
      setPlateDetails([])
    }
 
    //handle the response for scan qr in incubator section 
    const handleScanCode = (serielNumber : any) => {
      if(serielNumber) {
        handleScanCodeChange(serielNumber)
        setToastMessage(`Plate Seriel scan successfull`);
        setToastType("success");
      } else {
        setToastMessage("Plate Seriel scan failed");
        setToastType("error");
      }
      setShowToast(true)
    }

    //pass the obtained serial number to the api 
    const handleScanCodeInput = (result: any) => {
      try {
        const parsedResult = JSON.parse(result);
        if (typeof parsedResult === 'object') {
          if (parsedResult.hasOwnProperty('serial_number')) {
            handleScanCodeChange(parsedResult.serial_number)
            // setScanCode(parsedResult.serial_number)
          }
        }
      } catch (error) {
        handleScanCodeChange(result)
        console.error('Error parsing JSON:', error);
      }
    }

    // function to sort table
    const handleSort = (key: string, value: boolean) => {
      setSort(key)
      if (value) {
        setOrder("asc")
      } else {
        setOrder("desc")
      }
      setUseEffectRequired(true)
    }

    // function to sort in history table
    const handleSortHistory = (key: string, value: boolean) => {
      setSort(key)
      if (value) {
        setOrder("asc")
      } else {
        setOrder("desc")
      }
      setUseEffectRequired(true)
    }

    useEffect(() => {
      // Set a timer to update debouncedScanCode after 10 seconds
      const timerId = setTimeout(() => {
        setDebouncedScanCode(scanCode);
      }, 3000);
  
      // Cleanup the timer if the component is unmounted or scanCode changes
      return () => clearTimeout(timerId);
    }, [scanCode]);
  
    useEffect(() => {
      if (debouncedScanCode) {
        handleScanCodeInput(debouncedScanCode);
      }
    }, [debouncedScanCode]);

    const getActivityTypeList = async () => {
      try {
        const response = await userService.getActivityTypeList();
        if (response.status && response.statusCode === 200) {
          try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
              let rowData: any = response.data.map(
                (element: any, index: number) => {
                  return {
                    slno: index + 1,
                    id: element.id,
                    name: element.name,
                  };
                }
              );
              setActivityType(rowData);
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          setActivityType([]);
        }
      } catch (error) {
        console.error("user response", error);
      }
    };


  return (
    <div>
            <HeaderSection />
            <PageHeader pageHeader={t('operatorManagement.incubation')} />
            <CustomToast
                show={showToast}
                onClose={() => setShowToast(false)}
                delay={5000}
                message={toastMessage}
                toastType={toastType}
            /> 
            <div className='exceptionHandlingTab'>
                <button onClick={() => setTab("1")} className={tab === "1" ? 'colTabBtnSel': 'colTabBtn'}>{t('operatorManagement.incubation')}</button>
                <button onClick={() => setTab("2")} className={tab === "2" ? 'colTabBtnSel': 'colTabBtn'}>{t('operatorManagement.history')}</button>
            </div>

        {tab === "1" && (
            <div>
                    <div className='scanWrapLg'>
                        <div className='scanInputWrapLg p-2' style={{"width": "30%", "height":"20%"}}>
                            <input  type='text' ref={scanAreaRef} value={scanCode} onChange={(e) => setScanCode(e.target.value)} placeholder='Scan Code' />
                            {scanCode ? (
                              <p onClick={() => handleScanCodeClear()} className='scanAddText py-2 me-2 my-0 '>{t('operatorManagement.clear')}</p>
                            ) : (
                              <div onClick={() => setSerielScanShow(true)} className='scanBg '><BsQrCodeScan /></div>
                            )}
                        </div>

                        <div className="scanInputWrapLg p-2 ms-3" style={{"width": "30%", "height":"20%"}}>
                          <input
                            type="text"
                            value={selectedSerielNo}
                            onChange={(e) => setSelectedSerielNo(e.target.value)}
                            placeholder="Search Serial Number"
                            // ref={textFieldRef}
                          />

                          < IoSearchSharp 
                                    fontSize={25} 
                                    className='m-1' 
                                    color='grey'
                                    style={{"cursor":"pointer"}} 
                                    onClick={() => handleScanCodeChange(selectedSerielNo)}
                                />
                        
                        </div>


                        <div className='pt-3 mx-2'>
                          <p onClick={() => setShow(true)} className='exceptionBtn ms-3' >{t('operatorManagement.exception')}</p>
                        </div>
                        <div className='scannedDetailsWrap ps-3' style={{"marginLeft":"5%"}}>
                         <table className=''>
                            <tbody>
                                {Object.keys(data).map((key) => (
                                <tr  key={key}>
                                    <td className='keyText'>{key}</td>
                                    <td className='px-3'>:</td>
                                    <td className='valueText'>{data[key]}</td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                        {/* <p className='keyText m-0'>Area Name : <span className='valueText'> Name</span></p>
                        <p className='keyText my-1'>Activity Status : <span className='valueText'> Status</span></p> */}
                        </div>
                    </div>
                    <div className=' mx-3 pe-2' style={{"height":"35vh"}}>
                        <CustomTable  
                            data={plateDetails} 
                            columns={columns} 
                            isEditable={false} 
                            totalElements={totalElements}  
                            isActionVisible={true} 
                            isViewVisible={false} 
                            removable={true}
                            isSeachable={false}
                            tableHead={t('operatorManagement.incubation')}
                            startButton={true}
                            startButtonText={t('operatorManagement.start_incubation')}
                            endButton={true}
                            endButtonText={t('operatorManagement.stop_incubation')}
                            plateBatch={plateBatches}
                            plateSerialNumber={plateSerielNum}
                            areaListNumber={areaTypeNum}
                            ActivityType={activityType}
                            onStartClick={handleStartClick}
                            onEndClick={handleEndClick}
                            onFilterClick={handleFilter}
                            onRemoveClick={handleRemoveClick}
                            onSortClick={handleSort}
                        />
                    </div>
                    <div>
                      <Pagination
                        page={page}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                      />
                    </div>


            </div>
        )}

        {tab === "2" && (
            <div>
                    <div className=' mx-3 pe-2'>
                        <CustomTable  
                            data={incubationHistory} 
                            columns={columnsHistory} 
                            isEditable={false} 
                            totalElements={totalElementsHistory}  
                            isActionVisible={false} 
                            plateBatch={plateBatches}
                            plateSerialNumber={plateSerielNum}
                            isViewVisible={false} 
                            tableHead={t('operatorManagement.incubation_history')}
                            isSeachable={true}
                            onFilterClick={handleFilterHistory}
                            onSortClick={handleSortHistory}
                          />                           
                    </div>
                    <div>
                      <Pagination
                        page={pageHistory}
                        totalPages={totalPagesHistory}
                        handlePageChange={handlePageChangeHistory}
                      />
                    </div>
            </div>
        )}

        {/* add exception modal */}
        <AddException showModel={show} setShowModel={() => setShow(false)} />

        {/* seriel number scan modal */}
        <SerielNumberScanner show={serielScanShow} setShow={setSerielScanShow} handleScanCode={handleScanCode} />



    </div>
  )
}

export default IncubationArea