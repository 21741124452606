import React, { ChangeEvent, useEffect, useState } from "react";
import { workflow } from "../../../service/workflow";
import { GrEdit } from "react-icons/gr";
import { FaLessThanEqual } from "react-icons/fa";
import CustomToast from "../../../widgets/toaster";
import { Link } from "react-router-dom";
import { TfiClose } from "react-icons/tfi";
import { Modal } from "react-bootstrap";
import AlertModal from "../../../widgets/alertModal";

interface InnerWorkFlowProps {
  setTab: (type: string) => void;
  activityId: any;
  selectedIds: string;
  flowName: string;
  editWorkFlow: {
    id: number;
    processDetails: any[];
    status: string;
    name: string;
    master_activity_id: any;
  };
  setEditWorkFlow: React.Dispatch<
    React.SetStateAction<{
      id: number;
      processDetails: any[];
      status: string;
      name: string;
      master_activity_id: any;
    }>
  >;
}

const WorkflowInnerPage: React.FC<InnerWorkFlowProps> = ({
  setTab,
  activityId,
  selectedIds,
  editWorkFlow,
  flowName,
  setEditWorkFlow,
}) => {
  const [process, setProcess] = useState<Array<any>>([]);
  const [processId, setProcessId] = useState<any>("");
  // const [editWorkFlow, setEditWorkFlow] = useState<any>({});
  const [step, setStep] = useState(2);
  const [processField, setProcessField] = useState<Array<any>>([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [formValues, setFormValues] = useState<{ [key: string]: string }>({});
  const [rows, setRows] = useState([{ id: 1, content: "" }]);
  const [sequence, setSequence] = useState(1);
  const [selectedRowId, setSelectedRowId] = useState(0);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [plateTypes, setPlateTypes] = useState([]);
  const [equipmentList, setEquipmentList] = useState<Array<any>>([]);
  const [draftAlert, setDraftAlert] = useState(false);
  const [processFixedState, setprocessFixedState] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [processLength, setprocessLength] = useState(false);

  const handleProcessChange = (event: any) => {
    setProcessId(event.target.value);
    const selectedActivity = process.find(
      (act) => act.id === event.target.value
    );
    setProcessField(selectedActivity?.attributes);
    // Clear form data when activity changes
    // setFormData({});
  };

  useEffect(() => {
    ProcessList(editWorkFlow?.master_activity_id);
    setUseEffectRequired(false);
    getPlateTypeList();
    // listEquipments();
  }, [editWorkFlow, useEffectRequired]);

  useEffect(() => {
    getWorkFlowById(selectedIds);
  }, [useEffectRequired]);

  useEffect(() => {
    handleEditRow(editWorkFlow?.processDetails[0]?.id);
    setUseEffectRequired(false);
  }, [editWorkFlow?.processDetails, useEffectRequired]);

  useEffect(() => {
    const selectedOption = process.find((obj) => obj.fixedSequence === "1");
    if (selectedOption) {
      setProcessId(selectedOption.id);
      handleProcessChange({ target: { value: selectedOption.id } });
    }
    setUseEffectRequired(false);
  }, [process, useEffectRequired]);

  useEffect(() => {
    const selectedProcess = process.find((obj) => obj.id === processId);
    let hasFixedSequence = selectedProcess?.fixedSequence === "1";

    // if (process.some((obj: any) => obj.id === processId && hasFixedSequence && !processFixedState)) {
    //   setIsDisabled(true);
    // } else {
    //   setIsDisabled(false);
    // }
    // if (hasFixedSequence && processFixedState && processLength) {
    //   setIsDisabled(true);
    // }

    const shouldDisable =
      process.some(
        (obj: any) =>
          obj.id === processId && hasFixedSequence && !processFixedState
      ) ||
      (hasFixedSequence && processFixedState && processLength);

    setIsDisabled(shouldDisable);

    setUseEffectRequired(false);
  }, [
    processId,
    process,
    editWorkFlow,
    workflow,
    useEffectRequired,
    processFixedState,
  ]);

  useEffect(() => {
    listEquipments();
  }, [processId]);

  const backToHome = () => {
    setProcessField([]);
    setProcess([]);
    setFormValues({});
    setProcessId("");
    setSequence(1);
    setEditWorkFlow({
      id: 0,
      processDetails: [],
      status: "",
      name: "",
      master_activity_id: 0,
    });
    setTab("1");
  };

  const ProcessList = async (id: string) => {
    try {
      const response = await workflow.ProcessList(id);
      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map((element: any) => {
              return {
                id: element.id,
                name: element.name,
                attributes: element.attributes,
                fixedSequence: element.fixed_sequence,
                label_name: element.label_name,
              };
            });
            setProcess(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setProcess([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  const getWorkFlowById = async (id: string) => {
    try {
      const response = await workflow.EditById(id);
      if (response.status && response.statusCode === 200) {
        try {
          const rowData = {
            id: response.data.id,
            processDetails: response.data.process_details,
            status: response.data.status,
            name: response.data.name,
            master_activity_id: response.data.master_activity_id,
          };

          setEditWorkFlow(rowData);

          if (
            Array.isArray(rowData.processDetails) &&
            rowData.processDetails.length === 0
          ) {
            setprocessFixedState(true);
            setprocessLength(true);
            console.log(rowData.processDetails.length, "add modal true");
          } else {
            setprocessFixedState(false);
            setprocessLength(false);
            console.log(rowData.processDetails.length, "add modal false");
          }

          handleEditRow(rowData?.processDetails[0]?.id);
          ProcessList(response.data.master_activity_id);
          getPlateTypeList();
          // listEquipments();
        } catch (error) {
          console.log(error);
        }
      } else {
        setEditWorkFlow({
          id: 0,
          processDetails: [],
          status: "",
          name: "",
          master_activity_id: 0,
        });
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleNextClick = async () => {
    const attributeValues = Object.keys(formValues).map((key) => ({
      master_process_atributes_mapping_id: key,
      value: formValues[key],
    }));

    const dataToSend = {
      id: selectedRowId ? selectedRowId : 0,
      sequence: sequence ? sequence : 1,
      activity_workflow_definition_id: selectedIds,
      master_process_id: processId,
      attribute_values: attributeValues,
    };

    try {
      const response = await workflow.saveWorkFlowDetails(dataToSend);
      if (response.status && response.statusCode === 200) {
        setToastMessage(response.message);
        setToastType("success");
        setUseEffectRequired(true);
        closeModal();
      } else {
        setToastMessage(response.message);
        setToastType("error");
      }
      setShowToast(true);
      setDraftAlert(false);
    } catch (error) {
      console.error("user response", error);
    }
  };

  // const getNextSequence = () => {
  //   const maxSequence = Math.max(
  //     ...editWorkFlow?.processDetails.map((row) => Number(row.sequence))
  //   );
  //   return maxSequence + 1;
  // };

  const getNextSequence = () => {
    const sequences = editWorkFlow?.processDetails
      .map((row) => Number(row.sequence))
      .filter((num) => !isNaN(num)); // Filter out any non-numeric values

    const maxSequence = sequences.length > 0 ? Math.max(...sequences) : 0;

    return maxSequence + 1;
  };

  const handleAddRow = () => {
    setShowAddModal(true);
    setprocessFixedState(true);
    setFormValues({});
    setSelectedRowId(0);
    // setIsDisabled(false);
    const newSequence = getNextSequence();
    setSequence(newSequence);
  };

  const handleEditRow = (id: any) => {
    // setShowAddModal(true);
    setSelectedRowId(id);

    const itemToEdit: any = editWorkFlow?.processDetails?.find(
      (item: any) => item.id === id
    );

    const selectedActivity = process.find(
      (act) => act.id === itemToEdit?.master_process_id
    );
    setProcessField(selectedActivity?.attributes);
    setSequence(Number(itemToEdit?.sequence));
    setProcessId(itemToEdit?.master_process_id);

    const isFixedSequence = Number(itemToEdit?.sequence) === 1;
    console.log(isFixedSequence, "isFixedSequence");

    // If the sequence is 1, set processFixedState to true, else false
    if (isFixedSequence) {
      setprocessFixedState(false);
    } else {
      setprocessFixedState(true);
    }

    if (itemToEdit !== 0) {
      const newFormValues: { [key: string]: string } = {};

      itemToEdit?.attribute_values.forEach((obj: any) => {
        newFormValues[obj.master_process_atributes_mapping_id] = obj.value;
      });

      setFormValues(newFormValues);
    } else {
      setFormValues({});
    }
  };

  const closeModal = () => {
    setShowAddModal(false);
    setprocessFixedState(true);
    setErrors({});
  };

  const getPlateTypeList = async () => {
    try {
      const response = await workflow.listAllPlateTypes();
      if (response.status && response.statusCode === 200) {
        setPlateTypes(response.data);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  const listEquipments = async () => {
    // alert(processId)
    try {
      // const mappedProcessId = mapProcessId(processId); // Map processId before passing it to the API
      const response = await workflow.listEquipmentFilter(processId);

      if (response.status && response.statusCode === 200) {
        try {
          if (Array.isArray(response.data) && response.data?.length > 0) {
            let rowData: any = response.data.map(
              (element: any, index: number) => {
                return {
                  slno: index + 1,
                  id: element.id,
                  name: element.name,
                };
              }
            );
            setEquipmentList(rowData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setEquipmentList([]);
      }
    } catch (error) {
      console.error("user response", error);
    }
  };

  // Helper function to map processId values
  const mapProcessId = (id: string) => {
    switch (id) {
      case "1":
        return "1";
      case "8":
        return "3";
      case "10":
        return "4";
      case "11":
        return "6,7";
      default:
        return id;
    }
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};

    processField?.forEach((attr) => {
      const fieldName = attr.master_process_atributes_mapping_id;
      const value = formValues[fieldName];

      // Custom error messages for specific attributes
      switch (attr.name) {
        case "select_equipment":
          if (!value) newErrors[fieldName] = "Please select an equipment";
          break;
        case "plate_size_type":
          if (!value) newErrors[fieldName] = "Please select a plate size type";
          break;
        case "plate_grade":
          if (!value) newErrors[fieldName] = "Please enter the plate grade";
          break;
        case "colony_identification":
          if (!value)
            newErrors[fieldName] =
              "Please specify if colony identification is required";
          break;
        case "flora_identification":
          if (value === "")
            newErrors[fieldName] =
              "Please specify if flora identification is required";
          break;
        case "autoclave_stop_manual":
          if (value === "")
            newErrors[fieldName] = "Please specify if manual stop is needed";
          break;
        case "duration":
        case "over_due_intervel":
        case "buffer_time_for_start_process":
        case "buffer_time_for_stop_process":
        case "maximum_interval_for_next_process":
          if (!value) {
            newErrors[fieldName] = "This field is required";
          } else {
            // Validate the duration format hh:mm:ss
            const timePattern = /^(?:[01]\d|2[0-3]):[0-5]\d$/;
            if (!timePattern.test(value)) {
              newErrors[fieldName] = "Time must be in HH:MM format";
            }
          }
          break;

        // Positive integer validation
        case "over_due_intervel":
        case "buffer_time_for_start_process":
        case "buffer_time_for_stop_process":
        case "maximum_interval_for_next_process":
          if (!value) {
            newErrors[fieldName] = `${attr.label_name} is required`;
          } else if (!/^\d+$/.test(value)) {
            newErrors[
              fieldName
            ] = `${attr.label_name} must be a positive integer`;
          } else if (parseInt(value, 10) <= 0) {
            newErrors[
              fieldName
            ] = `${attr.label_name} must be greater than zero`;
          }
          break;

        // Min and Max temperature validation with comparison check
        case "min_temperature":
        case "max_temperature":
          if (!value) {
            newErrors[fieldName] = `${attr.label_name} is required`;
          } else if (!/^\d+$/.test(value)) {
            newErrors[
              fieldName
            ] = `${attr.label_name} must be a positive integer`;
          } else if (parseInt(value, 10) <= 0) {
            newErrors[
              fieldName
            ] = `${attr.label_name} must be greater than zero`;
          }

          // Fetching min and max temperature values for comparison
          // const minTemp = parseInt(formValues["min_temperature"], 10);
          // const maxTemp = parseInt(formValues["max_temperature"], 10);

          // if (!isNaN(minTemp) && !isNaN(maxTemp)) {
          //   if (minTemp >= maxTemp) {
          //     alert("done")
          //     newErrors["max_temperature"] = "Maximum temperature must be greater than minimum temperature";
          //     newErrors["min_temperature"] = "Minimum temperature must be less than maximum temperature";
          //   }
          // }
          break;

        // Default required field validation
        default:
          if (!value?.trim()) {
            newErrors[fieldName] = `${attr.label_name} is required`;
          }
          break;
      }
    });

    setErrors({ ...newErrors }); // Update the errors state

    // Return true if no errors, otherwise false
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveDraft = (event: any) => {
    event.preventDefault();

    const isValid = validateFields();

    if (isValid) {
      setDraftAlert(true);
    }
  };

  return (
    <>
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={5000}
        message={toastMessage}
        toastType={toastType}
      />
      <nav aria-label="breadcrumb" className="breadcrumb-container row">
        <div className="col-10 d-flex">
          <ol className="breadcrumb">
            <li
              className="breadcrumb-item dashboard-hover"
              onClick={backToHome}
              style={{ cursor: "pointer" }}
            >
              Workflow
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Process
            </li>
          </ol>
        </div>
        <div className="col-2 d-flex"></div>
      </nav>

      {step === 2 && (
        <div className="workflow-container">
          <div className="left-tab">
            <h4>{flowName}</h4>
            {editWorkFlow?.processDetails?.map((row: any) => (
              <div key={row.id} className="row-item">
                <div className="process-name">{row.process_name}</div>
                <div className="edit-icon-container">
                  <GrEdit
                    className="edit-icon"
                    onClick={() => {
                      handleEditRow(row.id);
                      setShowAddModal(true);
                    }}
                  />
                </div>
              </div>
            ))}
            {!editWorkFlow?.processDetails?.some(
              (row: any) => row.process_keyword === "autoclave"
            ) && (
              <div className="add-row-button-container">
                <button onClick={() => handleAddRow()} className="colTabBtnSel">
                  + Add Process
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      <Modal className="top-right-modal" centered={false} show={showAddModal}>
        <div>
          <div className="modalHead">
            <p className="modalHeadText ms-3 pt-3 p-2">Process</p>
            <div onClick={closeModal} className="p-2 mx-3 my-2">
              <TfiClose fill="#FFFFFF" />
            </div>
          </div>
        </div>
        <div className="selectWrap-email">
          <div className="form-group my-1">
            <label htmlFor="selectProcess" className="process-label">
              Select Process <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <select
              id="selectProcess"
              value={processId}
              disabled={isDisabled}
              className="placeholder-style"
              onChange={handleProcessChange}
              style={isDisabled ? { opacity: "0.5" } : { opacity: "1" }}
            >
              <option value="">Select</option>
              {process.map((obj, index) => (
                <option key={index} value={obj?.id}>
                  {obj?.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div
          className="selectWrap mt-5 mx-3"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div
            className="process-content row"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start", // Ensures alignment to the left
            }}
          >
            {processField?.map((attr, index) => (
              <div
                className={`form-group col-6 my-1 ${
                  index % 2 === 0 ? "pr-2" : "pl-2"
                }`}
                key={attr.id}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <label
                  htmlFor={attr.label_name}
                  style={{
                    marginBottom: "4px",
                    fontWeight: "400",
                    color: "#333",
                    fontSize: "11px",
                    // whiteSpace: "normal",
                    // wordBreak: "break-word",
                  }}
                >
                  {attr.label_name} <span style={{ color: "red" }}>*</span>
                </label>

                {[
                  "select_equipment",
                  "lab_test_type",
                  "plate_size_type",
                  "plate_grade",
                  "colony_identification",
                  "flora_identification",
                  "autoclave_stop_manual",
                ].includes(attr.name) ? (
                  <>
                    <select
                      id={attr.name}
                      name={attr.master_process_atributes_mapping_id}
                      style={{
                        width: "100%",
                        padding: "8px",
                        border: errors[attr.master_process_atributes_mapping_id]
                          ? "1px solid red"
                          : "1px solid #ccc",
                        borderRadius: "4px",
                        color: "#888",
                        minWidth: "180px",
                      }}
                      value={
                        formValues[attr.master_process_atributes_mapping_id] ||
                        ""
                      }
                      onChange={handleInputChange}
                    >
                      <option value="">Select {attr.label_name}</option>
                      {attr.name === "select_equipment" &&
                        equipmentList.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.name}
                          </option>
                        ))}
                      {attr.name === "plate_size_type" &&
                        plateTypes.map((obj: any, index) => (
                          <option key={index} value={obj?.id}>
                            {obj?.plate_type}
                          </option>
                        ))}
                      {attr.name === "colony_identification" && (
                        <>
                          <option value="1">Required</option>
                          <option value="0">Not Required</option>
                        </>
                      )}
                      {attr.name === "flora_identification" && (
                        <>
                          <option value="1">Required</option>
                          <option value="0">Not Required</option>
                        </>
                      )}
                      {attr.name === "autoclave_stop_manual" && (
                        <>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </>
                      )}
                      {attr.name === "lab_test_type" && (
                        <>
                          <option value="1">Automatic</option>
                          <option value="0">Manual</option>
                        </>
                      )}
                    </select>
                    {errors[attr.master_process_atributes_mapping_id] && (
                      <p
                        className="errorText"
                        style={{ color: "red", fontSize: "12px" }}
                      >
                        {errors[attr.master_process_atributes_mapping_id]}
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <input
                      type="text"
                      id={attr.name}
                      name={attr.master_process_atributes_mapping_id}
                      placeholder={
                        [
                          "duration",
                          "over_due_intervel",
                          "buffer_time_for_start_process",
                          "buffer_time_for_stop_process",
                          "maximum_interval_for_next_process",
                        ].includes(attr.name)
                          ? "hh:mm"
                          : "Please enter a value"
                      }
                      style={{
                        width: "100%",
                        padding: "8px",
                        border: errors[attr.master_process_atributes_mapping_id]
                          ? "1px solid red"
                          : "1px solid #ccc",
                        borderRadius: "4px",
                        color: "#888",
                        minWidth: "180px",
                        fontSize: "12px",
                      }}
                      value={
                        formValues[attr.master_process_atributes_mapping_id] ||
                        ""
                      }
                      onChange={handleInputChange}
                    />
                    {errors[attr.master_process_atributes_mapping_id] && (
                      <p
                        className="errorText"
                        style={{ color: "red", fontSize: "12px" }}
                      >
                        {errors[attr.master_process_atributes_mapping_id]}
                      </p>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="bottomArea">
          <button onClick={closeModal} className="modalCancelBtn">
            Cancel
          </button>
          <button
            className="squareOrangeBtn3"
            onClick={(e) => handleSaveDraft(e)}
          >
            Save as Draft
          </button>
        </div>
      </Modal>

      <AlertModal
        show={draftAlert}
        onHide={() => setDraftAlert(false)}
        title="Alert"
        message={`Save as Draft`}
        onConfirm={handleNextClick}
      />
    </>
  );
};

export default WorkflowInnerPage;
