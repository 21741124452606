export default [
  {
    id: 1,
    title: "PrdWF12001",
    allDay: true,
    start: new Date(2024, 10, 1),
    end: new Date(2024, 10, 1)
  },
  {
    id: 2,
    title: "PrdWF12001",
    start: new Date(2024, 10, 3, 9, 0, 0),
    end: new Date(2024, 10, 3, 12, 0, 0)
  },
  {
    id: 3,
    title: "PrdWF12001",
    start: new Date(2024, 10, 7, 14, 0, 0),
    end: new Date(2024, 10, 7, 15, 30, 0)
  },
  {
    id: 4,
    title: "SurfaceWF2",
    start: new Date(2024, 10, 15, 10, 30, 0),
    end: new Date(2024, 10, 15, 12, 30, 0)
  },
  {
    id: 5,
    title: "PrdWF12001",
    start: new Date(2024, 10, 21, 16, 0, 0),
    end: new Date(2024, 10, 21, 17, 30, 0)
  },
  {
    id: 6,
    title: "SurfaceWF3",
    start: new Date(2024, 10, 25, 18, 0, 0),
    end: new Date(2024, 10, 25, 20, 0, 0)
  },
  {
    id: 7,
    title: "PrdWF12001",
    start: new Date(2024, 10, 30, 9, 0, 0),
    end: new Date(2024, 10, 30, 18, 0, 0)
  }
];
